<template>
    <v-container
    class="pa-0"
    id="input-tab-container"
    >
      <!-- top background -->
      <div id="left-circle"></div>
      <div id="right-circle"></div>

        <!-- number input tab -->
      <v-container
      v-if="currentTab === 'input'"
      style="max-width: 600px; position: relative"
      >
        <!-- <div id="ci-1">
        </div> -->
        <div
          id="title"
          class="otpText--text"
          >
            Wa Verification
        </div>
        <v-container
        id="image-container"
        >
          <img
          :src="InputSVG"
          id="input-svg"
          />
        </v-container>
          <!-- <div id="ci-1">1</div>
          <div id="ci-2">2</div> -->
        <v-container>
            <div
            id="caption"
            class="otpText--text"
            >
              Enter Your Whatsapp Number
            </div>

            <!-- :rules="[rules.required, rules.phoneNumber]" -->
            <v-container id="number-input-container">
                <VueCountryCode
                id="country-code"
                class=""
                @onSelect="appendCountryCode"
                :preferredCountries="['id']"
                />
                <div class="ml-1 mr-4 otpText--text" id="country-code-string">+{{ countryCode }}</div>
                <v-text-field
                dense
                v-model="phoneNumber"
                :rules="[rules.required, rules.phoneNumber]"
                id="phone-input"
                outlined
                class="ml-2 pa-0"
                >
                </v-text-field>
            </v-container>
            <v-container
            class="pa-2"
            style="margin-top: 42px"
            >
              <v-btn
              v-if="isLoading === false"
              @click="login"
              width="100%"
              color="#14224A"
              class="white--text"
              >Send OTP
              </v-btn>
              <v-btn
              v-if="isLoading === true"
              width="100%"
              color="#14224A"
              class="white--text"
              loading
              >
              </v-btn>
            </v-container>
          </v-container>
      </v-container>

      <!-- confirmation tab -->
      <v-container
      v-if="currentTab === 'otpConfirmation'"
      style="max-width: 600px; position: relative"
      >
        <div
          id="title"
          >
            OTP Verification
        </div>
        <v-container
        id="image-container"
        >
          <img
          :src="VerifSVG"
          id="input-svg"
          />
        </v-container>
          <!-- otp input -->
          <v-container>
            <div
            id="caption"
            >
              We will send you one time password this phone number.
            </div>
            <div
            id="number-under-caption"
            >
            (+{{ countryCode }} {{ phoneNumber }})
            </div>
              <v-otp-input
              length="6"
              @finish="confirm"
              v-model="otp"
              :disabled="isLoading"
              type="number"
              >
              </v-otp-input>

              <!-- otp loading overlay -->
              <v-overlay absolute :value="isLoading">
                  <v-progress-circular
                  indeterminate
                  color="primary"
                  ></v-progress-circular>
              </v-overlay>
          </v-container>
          <!-- timer countdown -->
          <div
          v-if="countDown"
          id="timer"
          >
              Wait for {{ countDown }} before you can send again
          </div>

          <!-- send again button -->
          <v-btn
          v-if="countDown === 0 && resendLoading === false"
          elevation="2"
          @click="resend"
          color="#14224A"
          class="white--text"
          style="width: 100%"
          >send again</v-btn>
          <v-btn
          v-if="countDown === 0 && resendLoading === true"
          elevation="2"
          @click="resend"
          color="#14224A"
          class="white--text"
          loading
          style="width: 100%"
          ></v-btn>
          <div v-if="success">
            {{ success }}
          </div>

          <!-- error snackbar -->
          <v-snackbar
          v-model="showErrSnackbar"
          color="error"
          :timeout="2000"
          :top="true"
          style="z-index: 5 !important"
          >
          {{ errSnackbarMsg }}
          <template v-slot:action="{ attrs }">
            <v-btn
              color="red"
              text
              v-bind="attrs"
              @click="showErrSnackbar = false"
            >
              Close
            </v-btn>
          </template>
        </v-snackbar>
      </v-container>

      <!-- loading state after success verification -->
      <v-overlay :value="redirectLoading">
          <v-chip
          v-model="redirectLoading"
          color="primary"
          class="mx-8"
          >
            Redirecting you...
            <v-progress-circular
              indeterminate
              size="24"
              color="secondary"
              class="ml-4"
            ></v-progress-circular>
          </v-chip>
    </v-overlay>
    </v-container>

</template>

<script>
const baseUrl = process.env.VUE_APP_DB_API_URL
import VueCountryCode from 'vue-country-code'
const jwtKey = 'marketajwtkeyforlogin'

export default {
  name: 'LoginByWa',
  data() {
    return {
      currentTab: 'input',
      phoneNumber: '',
      errorMessages: 'error',
      isLoading: false,
      rules: {
        required: value => !!value || 'Required',
        phoneNumber: value => {
          const characters = '+1234567890'
          const validChar = characters.includes(value[value.length - 1])
          if (!validChar) return 'Invalid Number'
          else return true
        },
      },
      countDown: 10,
      resendLoading: false,
      otp: '',
      token: '',
      success: '',
      InputSVG: require('../../assets/images/svg/WaLogin-Input-BG.svg'),
      VerifSVG: require('../../assets/images/svg/WaLogin-Verif-BG.svg'),

      // temporary sender
      // senderNumber: '6282215379892',
      // instance_id: 'zlgjjky9',
      // senderSubId: 'sptdvu0z2',

      currentLoginLink: '',
      showErrSnackbar: false,
      errSnackbarMsg: '',
      redirectLoading: false,
      countryCode: '',
    }
  },
  components: {
    VueCountryCode,
  },
  watch: {
    countDown: {
      handler(value) {
        if (value > 0) {
          setTimeout(() => {
            this.countDown--
          }, 1000)
        }
      },
    },
  },
  async mounted() {
    await this.getLoginLink()
    this.countryCode = '62'
  },
  computed: {
    subId() {
      return this.$store.getters['auth/getUser'].sub_id
    },
  },
  methods: {
    nameKeydown(e) {
      if (/^\W$/.test(e.key)) {
        e.preventDefault()
      }
    },
    async login() {
      this.isLoading = true

      const res = await this.$store.dispatch('auth/createOtpCode', { loginLinkId: this.currentLoginLink.loginLinkId })
      if (res.status) {
        this.token = res.data.expected
        const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send`
        const formData = new FormData()
        const senderInstanceId = JSON.parse(this.currentLoginLink.instance).instance
        await this.checkPhoneNumberFormat(this.phoneNumber)
        // formData.append('roomId', `${senderInstanceId}-${this.countryCode + this.phoneNumber}`)
        // formData.append('senderNumber', `${this.currentLoginLink.phone_number}:aa` + `@bb`)
        // formData.append('subId', this.currentLoginLink.sub_id)
        // const verified = await this.$store.dispatch('auth/verifyCodeBeforeSend', {
        //   token: this.token,
        //   loginLinkId: this.currentLoginLink.loginLinkId,
        // })
        // // //console.log(this.countryCode + this.phoneNumber)
        // formData.append('content', verified.data.content)
        // const allDataName = []
        // formData.append('nameFile', JSON.stringify(allDataName))

        const sendMessagePayload = {
          roomId: `${senderInstanceId}-${this.countryCode + this.phoneNumber}`,
          subId: this.currentLoginLink.sub_id,
          token: this.token,
          loginLinkId: this.currentLoginLink.loginLinkId,
        }
        //console.log('send message payload :', sendMessagePayload)

        const response = await this.$store.dispatch('auth/sendOtpMessage', sendMessagePayload)

        //console.log('res :', response)

        // const config = {
        //   headers: {
        //     'content-type': 'multipart/form-data',
        //   },
        // }
        // const response = await this.$axios.post(endpoint, formData, config)

        // switch page
        if (response.status) {
          this.isLoading = false
          this.currentTab = 'otpConfirmation'
          this.startCountdown()
        } else {
          this.showErrSnackbar = true
          this.errSnackbarMsg = 'Error when sending code to the Phone Number'
        }
      } else {
        this.showErrSnackbar = true
        this.errSnackbarMsg = 'Error when generating code'
      }

      this.isLoading = false
    },
    startCountdown() {
      if (this.countDown > 0) {
        setTimeout(() => {
          this.countDown--
        }, 1000)
      }
    },
    async confirm() {
      this.isLoading = true
      const res = await this.$store.dispatch('auth/verifyOtpCode', {
        enteredCode: this.otp,
        expected: this.token,
        loginLinkId: this.currentLoginLink.loginLinkId,
        phoneNumber: this.countryCode + this.phoneNumber,
      })
      // //console.log('res :', res)
      // //console.log('data :', data)
      const data = res.data
      if (data) {
        const redirect_url = await this.isValidUrl(data.slugObject.slug)
        if (redirect_url) {
          this.redirectLoading = true
          window.location.replace(data.slugObject.slug)
        } else {
          this.showErrSnackbar = true
          this.errSnackbarMsg = 'Invalid URL registered with this data'
        }
      } else {
        this.showErrSnackbar = true
        this.errSnackbarMsg = 'Wrong OTP Code'
      }
      this.isLoading = false
    },
    async resend() {
      this.resendLoading = true
      this.success = ''
      this.otp = ''

      // const { data } = await this.$store.dispatch('auth/createOtpCode', {})

      // const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send`
      const res = await this.$store.dispatch('auth/createOtpCode', { loginLinkId: this.currentLoginLink.loginLinkId })
      if (res.status) {
        this.token = res.data.expected
        const endpoint = `${process.env.VUE_APP_API_GENERAL}messages/send`
        const formData = new FormData()
        const senderInstanceId = JSON.parse(this.currentLoginLink.instance).instance
        await this.checkPhoneNumberFormat(this.phoneNumber)
        formData.append('roomId', `${senderInstanceId}-${this.countryCode + this.phoneNumber}`)
        formData.append('senderNumber', `${this.currentLoginLink.phoneNumber}:aa` + `@bb`)
        formData.append('subId', this.currentLoginLink.sub_id)
        const verified = await this.$store.dispatch('auth/verifyCodeBeforeSend', {
          token: this.token,
          loginLinkId: this.currentLoginLink.loginLinkId,
        })
        // //console.log(this.countryCode + this.phoneNumber)
        formData.append('content', verified.data.content)
        const allDataName = []
        formData.append('nameFile', JSON.stringify(allDataName))

        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        }
        const response = await this.$axios.post(endpoint, formData, config)
      }
      this.resendLoading = false
    },
    async getLoginLink() {
      const res = await this.$store.dispatch('link/getOneLoginLink', {
        loginLinkId: this.$route.params.loginLinkId,
      })
      if (res.status) {
        this.currentLoginLink = res.data
        //console.log(this.currentLoginLink)
      }
    },
    async isValidUrl(url) {
      try {
        new URL(url)
      } catch (e) {
        console.error(e)
        return false
      }
      return true
    },
    async appendCountryCode({ name, iso2, dialCode }) {
      this.countryCode = dialCode
    },
    async checkPhoneNumberFormat(num) {
      if (num[0] === '0') {
        this.phoneNumber = this.phoneNumber.slice(1)
      } else if (num.slice(0, 2) === this.countryCode) {
        this.phoneNumber = this.phoneNumber.slice(2)
      } else if (num.slice(0, 3) === this.countryCode) {
        this.phoneNumber = this.phoneNumber.slice(3)
      } else if (num.slice(0, 4) === this.countryCode) {
        this.phoneNumber = this.phoneNumber.slice(4)
      }
    },
  },
}
</script>

<style>
container {
  display: flex;
  width: 100%;
  height: screenHeight;
  justify-content: center;
}

.page {
  height: 100vh;
}

#title {
  font-weight: 600;
  font-size: 30px;
  line-height: 38px;
  margin-bottom: 46px;
}

#caption {
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 19px;
  color: black;
  margin-bottom: 9px;
}

#tab-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  min-width: 200px;
  max-width: 650px;
}

#text-field {
}

#timer {
  size: 6px;
}

#loading-state {
  display: flex;
  flex-direction: row;
}

#input-svg {
  object-fit: contain;
  width: 321px;
  height: 223px;
}

#image-container {
  display: flex;
  justify-content: center;
  align-content: center;
  height: auto;
  margin-bottom: 25px;
}

#input-container {
  display: flex;
  justify-items: center;
  height: 50%;
  margin-top: 40px;
}

#input-tab-container {
  height: 100vh;
  padding: 0px;
}

#circles {
  width: 100vw;
  height: 50vh;
  position: absolute;
}

#left-circle {
  position: absolute;
  width: 100%;
  height: 407px;
  left: -30px;
  top: -130px;
  border-radius: 0px 50% 50% 50%;
  /* border-radius: 50%; */
  background: linear-gradient(191.1deg, rgba(31, 151, 158, 0.7) 34.74%, rgba(31, 151, 158, 0) 91.8%);
}

#right-circle {
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 407px;
  left: 30px;
  top: -130px;
  border-radius: 50% 0px 50% 50%;
  /* border-radius: 50%; */
  background: linear-gradient(191.1deg, #1f979e 34.74%, rgba(168, 217, 48, 0) 91.8%);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}

tmp {
  left: -68px;
  top: -95px;
}

#number-under-caption {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 19px;
  text-align: center;
  margin-top: 21px;
  margin-bottom: 28px;
  color: #3d3939;
}
#country-code {
  height: 40px;
}
#phone-input {
  font-weight: 400;
  font-size: 15px;
  padding-left: 55px;
}
#country-code-string {
  display: flex;
  justify-items: center;
  align-items: center;
  text-align: center;
  position: absolute;
  left: 80px;
  bottom: 8.6px;
  font-size: 15px;
  z-index: 99999;
}
#number-input-container {
  display: flex;
  justify-content: space-evenly;
  padding: 0px;
  height: 40px;
  position: relative;
}
</style>
